header.header-portal {
    height: 70px;
    padding: 0 20px;
    background-color: $third-color;
    position: fixed;
    width: 100%;
    z-index: 1000;
    h1 {
        text-align: center;
        font-size: 32px;
        color: $main-color;
        padding: 14px;
        span {
            text-transform: uppercase; } } }
