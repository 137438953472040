//mixins
$tablet-width: 1024px;
$phone-width: 600px;
@mixin tablet {
    @media (max-width: $tablet-width) {
        @content; } }

@mixin phone {
    @media (max-width: $phone-width) {
        @content; } }


@mixin print {
    @media print {
        @content; } }

$background-color: #FFF;
$main-color: #DBA53A;
$sec-color: #34312E;
$third-color: #212121;

$main-text-color: #212121;
$sec-text-color: #999999;
$light-text-color: #BBBBBB;

$font-headers: 'Baskervville', serif;
$font-text: 'Montserrat', sans-serif;

$header-width: 100%;
$header-height: 70vh;
$header-padding: 30px 15% 0 15%;

$btn-color: $main-color;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0; }

html {
    padding: 0;
    margin: 0; }

body {
    padding: 0;
    margin: 0; }

main > header {
    width: $header-width;
    height: $header-height;
    padding: $header-padding;
    position: relative;
    &.short-header {
        height: 50vh; } }

h1, h2, h3, h4, h5, h6, nav.menu ul li {
    font-family: $font-headers;
    color: $main-text-color;
    font-weight: 400; }
h1 {
    font-size: 64px;
    @include phone {
        font-size: 32px; } }
h2 {
    font-size: 42px;
    @include phone {
        font-size: 28px; } }
h3 {
    font-size: 36px;
    @include phone {
        font-size: 24px; } }
h4 {
    font-size: 32px;
    @include phone {
        font-size: 20px; } }
h5 {
    font-size: 24px;
    @include phone {
        font-size: 18px; } }
h6 {
    font-size: 20px;
    @include phone {
        font-size: 16px; } }

nav.menu ul li a {
    font-size: 20px;
    color: #FFFFFF; }
p {
    font-size: 20px;
    font-family: $font-text;
    color: $sec-text-color;
    font-weight: 300;
    @include phone {
        font-size: 16px; } }


a.call-to-action {
    display: inline-block;
    font-family: $font-text;
    cursor: pointer;
    text-decoration: none;
    background-color: $main-color;
    color: white;
    border: none;
    outline: none;
    padding: 14px 30px;
    font-size: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
    transition: background-color .3s ease-in-out;
    @include phone {
        font-size: 14px;
        padding: 12px 12px;
        text-align: center; }
    &:hover {
        background-color: lighten($main-color, 5%); } }

section {
    .content {
        position: relative;
        padding: 80px 15%; } }
main {
    &.dark-white {
        background-color: #FCFCFC; } }
