main .form.necro {
    border-bottom: none; }

section.necrologie-famille {
    display: none;
    &.visible {
        display: block; }
    .form {
        padding-bottom: 30px; }
    form {
        padding-top: 30px;
        width: 100% !important;
        button {
            margin: 20px 0;
            i {
                padding-right: 10px; } } }
    .input-group {
        max-width: 400px;
        &.full {
            max-width: none; }
        &.image-picker {
            label {
                position: absolute;
                color: #999999;
                top: 0;
                left: 0; } }
        .img-list {
            img {
                cursor: pointer;
                display: inline-block;
                width: 100px;
                height: 80px;
                object-fit: cover;
                object-position: center;
                margin: 0 10px;
                opacity: .5;
                transition: opacity .4s ease-in-out, border .4s ease-in-out;
                border: 2px solid transparent;
                &.selected {
                    opacity: 1;
                    border: 2px solid $main-color; }
                &:first-child {
                    margin-left: 0; }
                &:last-child {
                    margin-right: 0; } } } }


    .input-inline {
        padding: 5px 0;
        .togglable {
            position: relative;
            width: 32px;
            height: 17px;
            border-radius: 9px;
            border: 1px solid lightgray;
            transition: border-color .5s ease-in-out, background-color .5s ease-in-out;
            display: inline-block;
            vertical-align: top;
            &::after {
                position: absolute;
                content: ' ';
                width: 13px;
                height: 13px;
                background-color: lightgray;
                border-radius: 50%;
                top: 1px;
                left: 1px;
                transition: left .5s ease-in-out, background-color .5s ease-in-out; }
            &.selected {
                border-color: $main-color;
                background-color: $main-color;
                &::after {
                    left: 16px;
                    background-color: white; } } }
        label {
            padding-left: 6px;
            font-family: $font-text; } }
    .link-list {
        padding-top: 30px;
        font-family: $font-text;
        .link {
            display: inline-block;
            width: 400px;
            padding: 10px 0; }
        a {
            display: block;
            padding: 5px 0;
            font-size: 20px;
            color: $main-text-color;
            text-decoration: none;
            transition: color .5s ease-in-out;
            &:hover {
                color: $main-color; } }
        label {
            display: block;
            color: $sec-text-color;
            font-size: 16px;
            padding-bottom: 2px; } } }
