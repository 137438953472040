nav.portal-menu {
    width: 280px;
    position: fixed;
    top: 70px;
    bottom: 0;
    background-color: $sec-color;
    ul {
        li {
            padding: 0;
            color: white;
            cursor: pointer;
            transition: background-color .2s ease-in-out;
            &:hover {
                background-color: darken($main-color, 15%); }
            &.selected {
                background-color: $main-color; }
            i {
                width: 30px;
                font-size: 24px; }
            span {
                font-family: $font-text;
                font-size: 20px;
                text-transform: uppercase;
                padding-left: 20px; }
            &.disabled {
                pointer-events: none;
                color: gray;
                cursor: disabled; }
            a.i-link {
                padding: 20px;
                display: block; } } } }
