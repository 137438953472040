section.widget.ceremonie#ceremonie {
    form {
        width: 100%; }
    display: none;
    input[type="file"] {
        display: none; }
    table.music-list {}
    &.visible {
        display: block; }
    .form-label {
        font-size: 24px; }
    .half {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        .form {
            &.visites {
                margin-top: 30px; }
            .photo-list {
                margin-top: 30px;
                .photo.clonable {
                    display: none; }
                .photo {
                    position: relative;
                    width: 90px;
                    height: 90px;
                    border-radius: 4px;
                    border: 1px solid $light-text-color;
                    display: inline-block;
                    vertical-align: top;
                    margin: 8px;
                    .del-btn {
                        position: absolute;
                        bottom: 4px;
                        right: 4px;
                        background-color: $main-color;
                        border-radius: 4px;
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                        transition: box-shadow .5s ease-in-out;
                        &:hover {
                            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); }
                        i {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            color: white; } }
                    &.add {
                        position: relative;
                        border: 1px dashed $main-color;
                        transition: background-color .5s ease-in-out, border-color .5s ease-in-out;
                        cursor: pointer;
                        &:hover {
                            background-color: $main-color;
                            border-color: transparent;
                            i {
                                color: white; } }
                        i {
                            transition: color .5s ease-in-out;
                            font-size: 24px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            color: $main-color; } }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover; } } }
            &.musics {
                margin-top: 60px;
                tr.clonable {
                    display: none; } }
            &.demande {
                margin-top: 60px;
                textarea {
                    margin-top: 20px;
                    resize: none;
                    border: 1px solid $light-text-color;
                    display: block;
                    width: 100%;
                    height: 200px;
                    outline: none;
                    padding: 10px;
                    font-family: $font-text;
                    font-size: 16px; }
                button {
                    margin-top: 20px;
                    padding: 12px 28px;
                    outline: none;
                    border: none;
                    background-color: $main-color;
                    text-transform: uppercase;
                    color: white;
                    font-weight: 600;
                    font-size: 20px;
                    cursor: pointer;
                    border: 2px solid transparent;
                    transition: border-color .5s ease-in-out, background-color .5s ease-in-out, color .5s ease-in-out;
                    &:hover {
                        border: 2px solid $main-color;
                        background-color: white;
                        color: $main-color; } } } } } }
