section.documents {
    display: none;
    &.visible {
        display: block; }
    td.buttons {
        text-align: right; }
    .halves {
        padding-top: 60px; }

    .form-label {
        font-size: 24px; }
    .half {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        .todo, .link-list {
            padding-top: 30px; }
        .todo {
            ul.todo-list {
                list-style-type: none;
                li.list-item {
                    position: relative;
                    padding-left: 25px;
                    padding: 5px 0 5px 25px;
                    font-family: $font-text;
                    &::after {
                        content: ' ';
                        position: absolute;
                        background-image: url('../../images/todo-done.svg');
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 20px;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%); }
                    &.done {
                        &::after {
                            background-image: url('../../images/todo-not-done.svg'); } } } } }
        .link-list {
            font-family: $font-text;
            .link {
                padding: 5px 0; }
            a {
                font-size: 20px;
                color: $main-text-color;
                text-decoration: none;
                transition: color .5s ease-in-out;
                &:hover {
                    color: $main-color; } }
            label {
                display: block;
                color: $sec-text-color;
                font-size: 16px;
                padding-bottom: 2px; } } } }

section.printbox.popup {
    z-index: 9999;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #000C;
    display: none;
    overflow: auto;

    .toolbar {
        position: fixed;
        top: 0px;
        right: 0px;
        left: 0px;
        height: 70px;
        background-color: #000C;
        font-size: 2em;
        text-align: center;

        > * {
            display: inline-block;
            color: #FFF;
            margin: 20px;
            cursor: pointer; }

        .close:hover, .print:hover {
            color: #AAA; } }

    section.document {
        margin-top: 100px;
        .page {
            position: relative;
            display: block;
            background-color: white;
            width: 210mm;
            height: 296mm;
            padding: 20mm 25mm;
            margin: 20px auto;
            header, footer {
                -webkit-print-color-adjust: exact; }
            header {
                h1 {
                    margin: 10mm 0; }
                img {
                    height: 50px;
                    width: auto; }
                .address {
                    padding-top: 8pt;
                    text-align: right;
                    float: right; }
                .space {
                    //used only when page has no title
                    height: 10mm; } }
            footer {
                position: absolute;
                bottom: 10pt;
                left: 50%;
                transform: translateX(-50%);
                p {
                    -webkit-print-color-adjust: exact;
                    color: #666666; }
                .footline {
                    &::after {
                        position: absolute;
                        content: " ";
                        left: 50%;
                        transform: translateX(-50%);
                        width: 40%;
                        background-color: #d64541;
                        height: 1px; } } }
            .signature {
                display: block;
                margin: 10mm 0;
                > * {
                    display: inline-block; }
                .sign {
                    float: right;
                    margin-right: 10mm; } }

            section.split {
                display: block;
                h2 {
                    font-size: 14pt;
                    text-align: left;
                    font-weight: bold;
                    margin: 20px 0 30px; }
                > * {
                    display: inline-block;
                    width: 50%;
                    vertical-align: top; } }

            h1 {
                text-align: center;
                font-size: 18pt;
                margin: 0 0 10mm 0;
                padding: 0;
                display: block; }

            section.schedule {
                h3 {
                    margin: 20pt 0 10pt; } }
            section.necrologie {
                text-align: center;
                .box {
                    display: inline-block;
                    width: 80mm;
                    margin: 0;
                    height: 30mm;
                    padding: 3mm 6mm;
                    vertical-align: top;
                    margin: auto;
                    &.center {
                        width: 100%; }

                    > * {
                        display: inline-block;
                        width: 100%;
                        margin: 0.5mm; }

                    .phone, .fax {
                        width: 40%;
                        font-size: 9pt; }
                    .email {
                        width: 100%; }
                    .tip {
                        width 100% {}
                        font-size: 9pt; } } }

            h2 {
                text-align: center;
                font-size: 16pt;
                margin: 20pt 0 10pt;
                display: block; }

            h3 {
                text-align: center;
                font-size: 14pt;
                margin: 3mm;
                width: 100%;
                display: block; }

            p, li {
                font-size: 11pt;
                color: #000;
                margin-top: 10pt;
                font-family: $font-text;
                display: block;
                line-height: 20pt;
                &.center {
                    text-align: center; }
                &.mute {
                    font-size: 9pt;
                    color: #333; } }

            .field {
                display: inline-block;
                width: 50%;
                margin: 3pt 0;

                .label {
                    margin-right: 10pt;
                    display: inline;
                    font-weight: bold; }

                .value {
                    display: inline;
                    margin-right: 3mm; }

                &.wide {
                    width: 100%; }

                &.checkbox {
                    width: 100%;
                    display: block;
                    .label {
                        width: 100%; }
                    .values {
                        width: 100%;
                        padding: 3mm 0;
                        display: block;
                        .value {
                            display: inline-block;
                            min-width: 20%;
                            margin: 1mm 4mm 0 0;
                            i {
                                margin: 0 1mm 0 0; } } } } }

            table.field {
                font-family: $font-text;
                margin: auto;
                display: table;
                width: 100%;
                tbody {
                    display: table-header-group; }
                tr {
                    display: table-row; }
                th, td {
                    display: table-cell;
                    padding: 5pt;
                    font-size: 11pt;
                    text-align: left; }
                td:nth-child(2) {
                    text-align: right; } } } } }

@include print {
    section.printbox.popup {
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;

        table {}

        section.document {
            &:last-child {
                page-break-after: auto; }
            .page {
                margin: 0 0 1mm 0; } } } }

@page {
    margin: 0; }
