@mixin hide($time:0.5s, $pos:'height') {
    transition: opacity linear $time, $pos linear 0s $time;
    overflow: hidden; }

@mixin hidden($pos:'height') {
    #{$pos}: 0 !important;
    opacity: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 0 !important;
    pointer-events: none; }

section#login {
    background: $background-color;
    // background: linear-gradient(125deg, #DC582A, #EF3D2A)
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .logo {
        max-width: 42em;
        padding: 10em 5em;
        width: 100%;
        box-sizing: border-box;
        margin: auto; }

    form {
        .error {
            height: 35px;
            color: $main-text-color;
            font-weight: bold;
            padding: 0; }

        .error.hidden {
            display: none; }

        max-width: 30em;
        width: 100%;
        padding: 0 2em 0 2em;
        box-sizing: border-box;
        margin: auto;

        input {
            width: 100%;
            padding: 0;
            margin: 1em 0 0 0;
            height: 2.5em;
            border: none;
            line-height: 2.5em;
            color: #807e81;
            font-size: 1.2em;
            box-sizing: border-box;
            background-color: #FFF;
            font-size: 1em;
            border-bottom: 1px solid #333;
            outline: none;
            @include hide;

            &[type='submit'] {
                background-color: $btn-color;
                color: #FFF;
                cursor: pointer;
                border-bottom: none; } } }

    .options {
        max-width: 30em;
        width: 100%;
        margin-top: 1em;
        box-sizing: border-box;

        .forgot-password {
            float: right;
            color: #807e81;
            font-size: 1em;
            font-family: sans-serif;
            cursor: pointer;
            &:hover {
                color: $sec-text-color; } } } }

input {
    @include hide; }
input[name="email"] {
    .login &,
    .reset & {
        @include hidden; } }

input[name="password"] {
    .check_email & {
        @include hidden; } }
input[name="confirm"] {
    .check_email &,
    .login & {
        @include hidden; } }
input[name="reset"] {
    .check_email &,
    .login & {
        @include hidden; } }

input[name="submit"] {
    .check_email &,
    .login & {
        content: 'Connexion'; }
    .reset & {
        content: 'Initialisation'; } }

input, .forgot-password {
    .logged & {
        @include hidden; } }

.forgot-password {
    .check_email & {
        display: none; } }

.login {
    .forgot-password {
        display: block; } }
