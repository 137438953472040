section.widget.faire-part#faire-part {
    display: none;
    &.visible {
        display: block;
        overflow-x: scroll;
        white-space: nowrap; }
    .half {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        &.left {
            width: 420px; }
        &.right {
            margin-left: 40px; }
        .models {
            width: 520px;
            .form-label {
                display: block; }
            .model {
                display: inline-block;
                vertical-align: top;
                margin-left: 40px;
                margin-top: 40px;
                &:nth-child(even) {
                    margin-left: 0; }
                .model-box {
                    border-radius: 4px;
                    width: 210px;
                    height: 297px;
                    border: 1px solid lightgray;
                    position: relative;
                    cursor: pointer;
                    transition: border-color .5s ease-in-out;
                    .img-icon {
                        z-index: 2;
                        position: absolute;
                        width: 28px;
                        height: 28px;
                        left: 10px;
                        top: 10px;
                        background-color: $sec-text-color;
                        i {
                            color: white;
                            font-size: 16px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%); } }
                    img.bg {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        object-fit: cover;
                        width: 100%;
                        height: 100%; }
                    &:hover {
                        border-color: $main-color; } }
                .model-label {
                    font-family: $font-text;
                    color: $sec-text-color;
                    padding-top: 10px; }
                &.selected {
                    .model-box {
                        border: 3px solid $main-color; } } } }

        .preview {
            button.print-btn {
                @include print {
                    display: none; }
                position: absolute;
                top: 10px;
                left: 10px;
                width: 35px;
                height: 35px;
                background-color: $main-color;
                border: none;
                z-index: 999;
                padding: 0;
                i {
                    font-family: "Font Awesome 5 Free";
                    color: white;
                    font-size: 20px; } }
            position: relative;
            height: 100vh;
            width: 78vh;
            border: 1px solid lightgray;
            padding: 20px;
            * {
                font-family: 'Crimson Text', serif;
                color: $main-text-color; }
            img.bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
                &.none {
                    display: none; } }
            @include print {
                margin-left: 0;
                padding: 12mm;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                border: none;
                page-break-after: always;
                width: 185mm !important;
                height: 230mm !important; }

            .headline {
                text-align: center;
                p.intro {
                    display: block;
                    height: 7%;
                    position: relative; }
                .img-block, .name-block {
                    display: inline-block;
                    vertical-align: top;
                    height: 23%; }
                .img-block {
                    position: relative;
                    width: 40%;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain; } }
                .name-block {
                    width: 60%;
                    position: relative;
                    hgroup {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        transform: translate(-50%, -50%);
                        h3.gender {
                            font-size: 20pt;
                            @include print {
                                display: block; } }
                        h2.name {
                            font-size: 28pt;
                            text-align: center; } } } }

            .content-block {
                text-align: center;
                height: 25%;
                position: relative;
                p {
                    display: block; }
                .dates {
                    p {
                        font-size: 3vh;
                        margin: 8vh 0;
                        @include print {
                            font-size: 20pt;
                            margin: 12mm 0; } } }
                .info {
                    p {
                        font-size: 1.61vh;
                        margin: auto;
                        margin-top: 5mm;
                        width: 70%;
                        @include print {
                            font-size: unset; }
                        &:first-child {
                            margin-top: 0; } } } }
            .condo-block {
                position: absolute;
                left: 0;
                bottom: 20mm;
                padding-left: 20px;
                @include print {
                    padding-left: 12mm; } }
            .footline {
                -webkit-print-color-adjust: exact;
                &::after {
                    -webkit-print-color-adjust: exact;
                    content: ' ';
                    background-color: #d64541;
                    height: 1px;
                    position: absolute;
                    width: 30%;
                    transform: translateX(-50%);
                    left: 50%;
                    bottom: 0; }
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                margin-bottom: 16px;
                padding-bottom: 4px;
                p {
                    color: $sec-text-color; }
                @include print {
                    padding-bottom: 1pt;
                    margin-bottom: 4pt;
                    color: $sec-text-color; } } } } }
@page {
    margin: 0; }
