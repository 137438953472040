@import '../theme';

@import '../../app/ceremonie/ceremonie';
@import '../../app/documents/documents';
@import '../../app/faire_part/faire_part';
@import '../../app/familles/familles';
@import '../../app/necrologie/necrologie';
@import '../../app/souvenir/souvenirs';
@import '../../app/widgets/header/header';
@import '../../app/widgets/login/login';
@import '../../app/widgets/menu/menu';

html, body {
    background-color: $third-color;
    @include print {
        background-color: white; } }
button {
    padding: 10px;
    background-color: $main-color;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    color: white;
    * {
        font-size: 16px;
        color: white; } }
a.i-link {
    color: inherit;
    text-decoration: none; }
.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000A;
    z-index: 9999;
    i {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        left: 50%;
        margin-left: -10px;
        color: #FFF; } }
main {
    padding: 120px 40px 40px 320px;
    background-color: white;
    > section.page {
        min-height: 100vh;
        min-height: calc(100vh - 160px); }

    form {
        label.form-label {
            font-size: 24px; }
        font-family: $font-text;
        width: 400px;
        .input-group {
            position: relative;
            padding-top: 25px;
            padding-bottom: 2px;
            margin-top: 30px;
            border-bottom: 1px solid $sec-text-color;
            &.no-border {
                border-bottom: none; }
            &.multiple {
                padding-top: 0; }
            .error-message {
                display: block;
                color: red;
                font-family: $font-text; }
            .data {
                line-height: 1.5em; }
            input.data, textarea {
                border: none;
                font-family: $font-text;
                font-size: 16px;
                width: 100%;
                padding: 5px; }
            textarea {
                resize: none;
                height: 80px; }
            label {
                position: absolute;
                color: $sec-text-color;
                top: 0;
                left: 0; }
            input.photo-input {
                display: none; }
            img.photo-input {
                max-width: 180px;
                max-height: 280px;
                object-fit: contain; }
            .input {
                padding-top: 20px;
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 30%;
                text-align: right;
                label {
                    right: 0;
                    left: auto; }
                &:first-child {
                    text-align: left;
                    width: 70%;
                    label {
                        left: 0;
                        right: auto; } } } } }

    table {
        margin-top: 20px;
        width: 100%;
        border-collapse: collapse;
        font-family: $font-text;
        tbody {
            tr {
                width: 100%;
                &:nth-child(odd) {
                    background-color: #F2F2F2; }
                td {
                    padding: 10px;
                    &.right {
                        text-align: right; }
                    a {
                        i {
                            color: $main-color;
                            transition: color .5s ease-in-out; }
                        &:hover {
                            i {
                                color: $sec-color; } } } }
                &.add {
                    background-color: transparent;
                    td {
                        padding: 10px 0;
                        i {
                            color: $main-color;
                            border-radius: 4px;
                            border: 1px dashed $main-color;
                            padding: 10px;
                            width: 100%;
                            transition: background-color .5s ease-in-out, border-color .5s ease-in-out, color .5s ease-in-out;
                            cursor: pointer;
                            &:hover {
                                background-color: $main-color;
                                border-color: transparent;
                                color: white; } } } } } }
        thead {
            tr {
                background-color: none;
                text-align: left;
                th {
                    padding: 10px;
                    font-size: 20px;
                    font-weight: 400;
                    &.right {
                        text-align: right; } } } } } }
@include print {
    header, footer, nav {
        display: none; }
    main {
        padding: 0;
        * {
            display: none; } }

    section#faire-part {
        * {
            display: none; }
        .printable {
            width: 100%;
            height: 100%;
            display: block;
            * {
                display: unset; } } }
    .printable {
        * {
            display: unset; }
        display: unset;
        .document {
            overflow: visible; }
        .page {
            margin: 0 auto; } } }


//==INCLUDE familles style
//main
//  padding: 120px 40px 40px 320px
//  background-color: white
//  section
//      min-height: 100vh
//      min-height: calc(100vh - 160px)
//      .form
//          label.form-label
//              font-size: 24px
//          form
//              font-family: $font-text
//              width: 400px
//              .input-group
//                  position: relative
//                  padding-top: 25px
//                  padding-bottom: 2px
//                  margin-top: 30px
//                  border-bottom: 1px solid $sec-text-color
//                  &.no-border
//                      border-bottom: none
//                  &.multiple
//                      padding-top: 0
//                  .error-message
//                      display: block
//                      color: red
//                      font-family: $font-text
//                  .data
//                      line-height: 1.5em
//                  input.data, textarea
//                      border: none
//                      font-family: $font-text
//                      font-size: 16px
//                      width: 100%
//                      padding: 5px
//                  textarea
//                      resize: none
//                      height: 80px
//                  label
//                      position: absolute
//                      color: $sec-text-color
//                      top: 0
//                      left: 0
//                  input.photo-input
//                      display: none
//                  img.photo-input
//                      max-width: 180px
//                      max-height: 280px
//                      object-fit: contain
//                  .input
//                      padding-top: 20px
//                      position: relative
//                      display: inline-block
//                      vertical-align: top
//                      width: 30%
//                      text-align: right
//                      label
//                          right: 0
//                          left: auto
//                      &:first-child
//                          text-align: left
//                          width: 70%
//                          label
//                              left: 0
//                              right: auto

//          table
//              margin-top: 20px
//              width: 100%
//              border-collapse: collapse
//              font-family: $font-text
//              tbody
//                  tr
//                      width: 100%
//                      &:nth-child(odd)
//                          background-color: #F2F2F2
//                      td
//                          padding: 10px
//                          &.right
//                              text-align: right
//                          a
//                              i
//                                  color: $main-color
//                                  transition: color .5s ease-in-out
//                              &:hover
//                                  i
//                                      color: $sec-color
//                      &.add
//                          background-color: transparent
//                          td
//                              padding: 10px 0
//                              i
//                                  color: $main-color
//                                  border-radius: 4px
//                                  border: 1px dashed $main-color
//                                  padding: 10px
//                                  width: 100%
//                                  transition: background-color .5s ease-in-out, border-color .5s ease-in-out, color .5s ease-in-out
//                                  cursor: pointer
//                                  &:hover
//                                      background-color: $main-color
//                                      border-color: transparent
//                                      color: white
//              thead
//                  tr
//                      background-color: none
//                      text-align: left
//                      th
//                          padding: 10px
//                          font-size: 20px
//                          font-weight: 400
//                          &.right
//                              text-align: right
@include print {
    header, nav, section, footer {
        display: none; }
    main {
        padding: 0; }
    section#faire-part {
        * {
            display: none; }
        .printable {
            width: 100%;
            height: 100%;
            display: block;
            * {
                display: unset; } } } }
