body {
    &.model-simple {
        @include print {
            -webkit-print-color-adjust: exact;
            width: 70mm !important;
            height: auto !important;
            section.widget.souvenir#souvenir {
                .souvenir-models {
                    display: block;
                    * {
                        display: none; }
                    .model.form.simple {
                        display: block;
                        .model-preview {
                            display: block;
                            * {
                                display: block; } }
                        .face {
                            border: none;
                            margin: 0;
                            box-shadow: none; } } } } } }
    &.model-double {
        @include print {
            -webkit-print-color-adjust: exact;
            width: 150mm !important;
            height: auto !important;
            section.widget.souvenir#souvenir {
                .souvenir-models {
                    display: block;
                    * {
                        display: none; }
                    .model.form.double {
                        display: block;
                        .model-preview {
                            display: block;
                            * {
                                display: block; } }
                        .face {
                            white-space: nowrap;
                            border: none;
                            margin: 0;
                            box-shadow: none;
                            overflow: hidden;
                            .subface {
                                border: none;
                                white-space: normal;
                                border: none;
                                display: inline-block;
                                vertical-align: top; } } } } } } }
    &.model-pliroule {
        @include print {
            -webkit-print-color-adjust: exact;
            width: 295mm !important;
            height: auto !important;
            section.widget.souvenir#souvenir {
                .souvenir-models {
                    display: block;
                    * {
                        display: none; }
                    .model.form.pliroule {
                        display: block;
                        .model-preview {
                            display: block;
                            * {
                                display: block; } }
                        .face {
                            white-space: nowrap;
                            border: none;
                            margin: 0;
                            box-shadow: none;
                            overflow: hidden;
                            .subface {
                                border: none;
                                white-space: normal;
                                border: none;
                                display: inline-block;
                                vertical-align: top; } } } } } } } }

section.widget.souvenir#souvenir {
    display: none;
    @include print {
        * {
            display: none; } }
    &.visible {
        display: block;
        overflow-x: scroll;
        white-space: nowrap; }
    .model {
        margin: 50px 0;
        @include print {
            margin: 0; }
        .select-model {
            margin: 20px 0;
            @include print {
                margin: 0; } }
        form.informations {
            white-space: normal;
            .input-group.half {
                width: 50%;
                display: inline-block;
                vertical-align: top;
                @include print {
                    display: none; }
                img.photo-input {
                    max-height: 180px; } } }
        .model-preview, form.informations, .print {
            display: none;
            .face {
                border: 1px solid lightgray;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
                overflow: hidden; } }
        &.selected {
            @include print {
                display: block; }
            .print {
                display: block;
                margin: 10px 0; }
            .model-preview, form.informations {
                display: inline-block;
                vertical-align: top; }
            .model-preview {
                padding-left: 30px;
                @include print {
                    padding-left: 0; } }
            .select-model {
                display: none; } }

        .model-preview {
            white-space: normal;
            font-family: 'Crimson Text';
            .face {
                display: inline-block;
                margin: 20px;
                &:first-child {
                    margin-left: 0; }
                .subface {
                    overflow: hidden;
                    display: inline-block;
                    vertical-align: top;
                    border-right: 1px solid lightgray;
                    @include print {
                        border-right: none; }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center; } } } }
        &.pliroule {
            .model-preview {
                @include print {
                    display: block;
                    * {
                        display: block; } }
                .face {
                    margin: 20px 0;
                    width: 295mm;
                    height: 130mm;
                    white-space: nowrap;
                    overflow: hidden;
                    &.face-1 {
                        .subface-3 {
                            .text {}
                            .img {
                                height: 80%; }
                            .infos {} }
                        .subface-2 {
                            .img {
                                height: 50%; } } }
                    &.face-2 {
                        .subface-2 {
                            .text {
                                display: table;
                                width: 100%;
                                height: 100%;
                                p.thanks {
                                    display: table-cell;
                                    vertical-align: middle; } } }

                        .subface-3 {
                            margin: auto;
                            .img {
                                margin: auto;
                                height: 70%;
                                width: 60%;
                                margin-top: 20%; } } }
                    .subface {
                        overflow: hidden;
                        white-space: normal;
                        width: 33.333%;
                        height: 100%;
                        text-align: center;
                        position: relative;
                        &.padded {
                            padding: 5mm; }
                        .funetilmant {
                            position: absolute;
                            bottom: 10px;
                            left: 50%;
                            transform: translateX(-50%);
                            span {
                                display: inline-block;
                                color: gray;
                                border-bottom: 1px solid red; } }
                        text-align: center;
                        * {
                            font-family: 'Crimson Text'; }
                        p {
                            font-size: 11pt;
                            color: black;
                            &.deathname, &.overhead {
                                font-size: 13pt; }
                            &.deathname {
                                font-size: 15pt;
                                font-weight: 600;
                                margin-bottom: 3mm; } }
                        .infos {
                            margin-top: 2mm;
                            p {
                                font-weight: 300; } }
                        .icon {
                            margin-bottom: 10mm;
                            img {
                                height: auto;
                                margin: auto;
                                width: 30px; } }

                        q.quote {
                            display: block;
                            margin-top: 3mm;
                            font-style: italic;
                            font-size: 13pt;
                            height: auto; }
                        .quote-author {
                            font-size: 11pt;
                            margin-top: 3mm;
                            text-align: right; }
                        p.thanks, .quote {
                            height: 50%; }
                        p.thanks {
                            &.centered {} } } } } }

        &.double {
            .model-preview {
                @include print {
                    display: block;
                    * {
                        display: block; } }
                .face {
                    margin: 20px 0;
                    width: 150mm;
                    height: 100mm;
                    white-space: nowrap;
                    overflow: hidden;
                    .subface {
                        overflow: hidden;
                        white-space: normal;
                        width: 50%;
                        height: 100%;
                        text-align: center;
                        .funetilmant {
                            margin-top: 90mm;
                            span {
                                display: inline-block;
                                color: gray;
                                border-bottom: 1px solid red; } } }
                    &.face-2 {
                        .subface-1, .subface-2 {
                            padding: 20mm 5mm 0 5mm;
                            text-align: center;
                            * {
                                font-family: 'Crimson Text'; }
                            p {
                                font-size: 11pt;
                                color: black;
                                &.deathname, &.overhead {
                                    font-size: 13pt; }
                                &.deathname {
                                    font-weight: 600; } }
                            .infos {
                                margin-top: 6mm;
                                p {
                                    font-weight: 300; } }
                            .icon {
                                margin-bottom: 10mm;
                                img {
                                    height: auto;
                                    margin: auto;
                                    width: 30px; } }

                            q.quote {
                                font-style: italic; }
                            p.thanks, .quote {
                                height: 50%; } } } } } }
        &.simple {
            .model-preview {
                @include print {
                    display: block;
                    * {
                        display: block; } }

                .face {
                    position: relative;
                    width: 70mm;
                    height: 100mm;
                    font-family: 'Crimson Text';
                    * {
                        font-family: 'Crimson Text'; }
                    .funetilmant {
                        position: absolute;
                        bottom: 10px;
                        left: 50%;
                        transform: translateX(-50%);
                        span {
                            display: inline-block;
                            color: gray;
                            border-bottom: 1px solid red; } }
                    &.face-1 {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center; } }
                    &.face-2 {
                        padding: 20mm 5mm 0 5mm;
                        text-align: center;

                        p {
                            font-size: 11pt;
                            color: black;
                            &.deathname, &.overhead {
                                font-size: 13pt; }
                            &.deathname {
                                font-weight: 600; } }
                        .infos {
                            margin-top: 6mm;
                            p {
                                font-weight: 300; } }
                        .icon {
                            margin-bottom: 10mm;
                            img {
                                margin: auto;
                                width: 30px; } } } } } } } }
