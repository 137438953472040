section.widget.familles-admin {
    display: none;
    &.visible {
        display: block; }
    button.add-famille {
        margin: 20px;
        i {
            padding-right: 10px; }
        span {} }

    .kanban {
        padding: 20px 0;
        overflow-x: scroll;
        white-space: nowrap;

        .kanban-step {
            display: inline-block;
            width: 400px;
            vertical-align: top;
            padding: 10px;
            h2 {
                font-size: 20px;
                font-family: $font-text; }
            .item {
                position: relative;
                padding: 10px;
                margin: 20px 0;
                background-color: #FCFCFC;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
                font-family: $font-text;
                h4 {
                    font-family: $font-text;
                    font-weight: 400;
                    font-size: 18px;
                    padding-top: 4px; }
                .avatar {
                    right: 10px;
                    top: 10px;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 1px solid lightgray; }
                .due-date {
                    color: $sec-text-color;
                    padding: 10px 0;
                    i {
                        margin-right: 6px; } }
                .bottom {
                    .progress {
                        display: inline-block;
                        vertical-align: top;
                        width: calc(100% - 30px);
                        padding: 6px 10px 0 0;
                        .container {
                            height: 4px;
                            width: 100%;
                            background-color: lightgray;
                            border-radius: 2px;
                            position: relative;
                            overflow: hidden;
                            .done {
                                position: absolute;
                                left: 0;
                                width: 60%;
                                top: 0;
                                bottom: 0;
                                background-color: #D24D57; } } }
                    .handle {
                        text-align: center;
                        width: 30px;
                        display: inline-block;
                        vertical-align: top;
                        i {
                            color: $sec-text-color; } } } } } } }

section.form {
    .form-group {
        padding: 20px;
        display: block;
        label.form-group-label {
            font-size: 24px; }
        .form-subgroup {
            box-shadow: 0 3px 6px rgba(0, 0, 0 ,0.2);
            box-sizing: content-box;
            max-height: 3000px;
            transition: max-height 1s ease-in-out;
            overflow: hidden;
            padding: 20px 30px 12px 30px;
            margin: 20px 0;
            border-radius: 3px;
            background-color: white;
            label.form-subgroup-label {
                font-family: $font-text;
                font-size: 20px;
                position: relative;
                display: block;
                margin-bottom: 10px;
                i {
                    position: absolute;
                    right: 20px;
                    transition: transform 1s ease-in-out; } }
            &.retract {
                max-height: 35px !important;
                label.form-subgroup-label {
                    i {
                        transform: rotateZ(180deg); } } } } }
    .opt-group {
        opacity: .5;
        display: inline-block;
        padding: 10px;
        margin: 5px 0;
        border: 1px solid lightgray;
        transition: opacity .4s ease-in-out;
        .input-group {
            input, label {
                pointer-events: none; } }
        &.input-group {
            label {
                left: 10px; } }
        &.visible {
            opacity: 1;
            .input-group {
                input, label {
                    pointer-events: fill; } } } }

    .add-famille-form {
        display: none;
        &.show {
            background-color: #FAFAFA;
            position: fixed;
            overflow: scroll;
            display: block;
            left: 280px;
            top: 70px;
            bottom: 0;
            right: 0; } }
    form {
        button.prev, button.next, button.save {
            display: inline-block;
            margin-right: 20px;
            &.save {
                i {
                    padding-right: 10px; } }
            &.prev {
                i {
                    padding-right: 10px; } }
            &.next {
                i {
                    padding-left: 10px; } } }
        .group-label {
            font-size: 20px;
            font-family: $font-text; }
        .sub-group {
            margin: 24px 0 12px 0;
            .group-label {
                font-size: 16px;
                font-weight: 600; } }
        .input-group {
            position: relative;
            min-height: 30px;
            margin-top: 10px;

            input, textarea {
                border: 1px solid lightgray;
                margin-top: 30px;
                padding: 6px;
                font-size: 16px;
                width: 300px;
                font-family: $font-text;
                + label {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    font-family: $font-text;
                    color: dimgray; }
                &:checked {
                    + label {
                        color: $main-text-color; } }
                &:focus {
                    outline: none;
                    border-color: $main-color;
                    + label {
                        color: $main-color; } } }
            textarea {
                width: 500px; }
            &.radio, &.checkbox {
                min-height: 0;
                margin: 10px 0;
                input {
                    width: auto;
                    margin: 5px 0;
                    + label {
                        position: static; }
                    &[type="checkbox"], &[type="radio"] {
                        + label {
                            padding-left: 10px; } } } }
            label {} }

        .form-subgroup {
            width: 600px; }
        .input-group {
            border-bottom: none;
            input, textarea {
                margin-top: 10px;
                &.has-error {
                    border-color: red;
                    + label {
                        color: red; } } } }
        .error-message {
            padding: 10px 0;
            font-weight: 600; }
        button.save {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); } } }
